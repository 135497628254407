import createStore from 'unistore';

import { connect as originalConnect, Provider as originalProvider } from 'unistore/preact';
import { IBid } from '../services/provider';
import { IAdsService } from '../types/IAdsService';
import { IConfig } from '../types/IConfig';

export const connect: any = originalConnect as any;
export const Provider: any = originalProvider;

export enum State {
  CTA,
  VIDEO,
  FALLBACK,
  FINISH,
}

export interface IState {
  config: IConfig;
  service?: IAdsService;
  generalState: State;
  generalDuration: number;
  generalTimeLeft: number;
  progressTimeout?: ReturnType<typeof setTimeout>;
  videoUrl: string;
  videoPlaying: boolean,
  videoVolume: number;
  videoAdCount: number;
  videoRetryFired: boolean;
  videoRetryCount: number;
  videoSize: [number, number];
  videoIsLoading: boolean;
  videoIMAFailed?: boolean;
  videoBid?: { bidder: string, cpm: number, url: string };
  bids?: IBid[];
  manualPause?: boolean;
};

type DropFirst<T extends unknown[]> = T extends [any, ...infer U] ? U : never;
type ActionsReturn<T extends { [key: string]: (...p: any[]) => any }> = {
  [K in keyof T]: (...p: DropFirst<Parameters<T[K]>>) => ReturnType<T[K]>;
}
export type IStore = IState & ActionsReturn<typeof actions>;

const initialState: IState = {
  generalState: State.CTA,
  generalDuration: 30,
  generalTimeLeft: 0,
  videoUrl: '',
  videoPlaying: false,
  videoVolume: 1,
  videoAdCount: 1,
  videoSize: [640, 480],
  videoIsLoading: true,
  videoRetryFired: false,
  videoRetryCount: 0,
  config: {} as any,
  manualPause: false,
};

export const newStore = () => createStore(initialState);

export const setConfig = (_: IState, config: IConfig, service: IAdsService) => {
  if (!config.finalCallback) {
    config.finalCallback = window.videoAdConfigs?.finalCallback || (() => {
      const endPrerollEvent = new Event("onPrerollEnd");
      const prerollElem = document.getElementById("ark_pre-roll");
      if (prerollElem) prerollElem.dispatchEvent(endPrerollEvent);
    });
  }
  if (!config.onCTAStateChange) {
    config.onCTAStateChange = (({ isShowing }: any) => {
      console.warn('CTAStateChange: ' + isShowing);
    });
  }
  return { config, service };
};

declare global {
  interface Window {
    ReportingObserver: any;
  }
}

export const actions = {
  setSize(_: IState, w: number, h: number) {
    return { videoSize: [w, h] };
  },

  requestBids(state: IState) {
    const { config, service } = state;
    if (!service) return { generalState: State.FALLBACK };
    const generalTimeLeft = config.duration || 30;
    return service.request(config)
      .then((bids) => ({ bids, generalTimeLeft, generalDuration: generalTimeLeft }));
  },

  fallback(state: IState) {
    const { service } = state;
    if (!service || service.disableFallback) return actions.finish(state);
    return { generalState: State.FALLBACK, generalDuration: 10, generalTimeLeft: 10 };
  },

  async startVideo(state: IState, retry = 0) {
    const { bids, config, videoIMAFailed } = state;

    const bid = bids && bids.shift();
    if (!bid) return actions.fallback(state);

    config.container.classList.add('playing');
    config.onCTAStateChange({ isShowing: false });

    const videoUrl = await bid.provider.buildVideoUrl(bid, videoIMAFailed);
    return { videoUrl, generalState: State.VIDEO, videoRetryCount: retry };
  },

  adStarted(state: IState, event: any) {
    const generalDuration =  event?.ad?.data?.duration || 30;
    const videoPlaying = true;
    return { videoPlaying, generalDuration, generalTimeLeft: generalDuration };
  },

  adProgress(state: IState, event: any) {
    const { duration, adBreakDuration, currentTime } = event.getAdData();
    const generalTimeLeft = duration - currentTime;
    return { generalTimeLeft };
  },

  imaFailed(state: IState) {
    return { videoIMAFailed: true };
  },

  async videoError(state: IState, e: any, details?: any) {
    const { videoRetryCount, generalState } = state;

    console.error('VideoError', e);
    if (generalState !== State.VIDEO) return;

    return actions.startVideo(state, videoRetryCount + 1);
  },

  videoCompleted(state: IState) {
    return actions.finish(state);
  },

  adComplete(state: IState) {
    // TODO: Workaround AdPods
    return actions.finish(state);
  },

  finish(state: IState) {
    const { config } = state;
    if (config.finished) return;
    config.finished = true;
    try {
      config.finalCallback();
    } catch (e) {
      // TODO: Report a problem about final callback
    }
    return { generealState: State.FINISH };
  },

  setVolume(_: IState, videoVolume: number) {
    return { videoVolume };
  },

  skipClicked() {
    return { generalTimeLeft: 0 };
  },

  handlePauseToggle(state: IState, isPaused: boolean) {
    return { manualPause: isPaused };
  },
};

