import { Ref, RefObject } from 'preact';
import { useEffect, useState, useCallback } from 'preact/hooks';
import { loadScript } from '../../utils/loadScript';

const imaUrl = `https://imasdk.googleapis.com/js/sdkloader/ima3${location.href.includes('ark_ima_debug') ? '_debug' : '' }.js`;
export const imaLoaded = loadScript(imaUrl);

type NullableRef<T> = RefObject<T | null>;
type ContainerRef = NullableRef<HTMLDivElement>;
type VideoRef = NullableRef<HTMLVideoElement>;

interface DisplayContainer {
  initialize(): void;
}

interface AdsRequest {
  adTagUrl: string;
  setAdWillAutoPlay(autoPlay: boolean): void;
  setAdWillPlayMuted(muted: boolean): void;
  contentTitle: string;
  pageUrl: string;
  forceNonLinearFullSlot: boolean;
  linearAdSlotWidth: number;
  linearAdSlotHeight: number;
  nonLinearAdSlotWidth: number;
  nonLinearAdSlotHeight: number;
}

interface AdsLoader {
  requestAds(request: AdsRequest): void;
  addEventListener(event: 'loaded', listener: (event: { getAdsManager(): AdsManager }) => any, param: boolean): void;
  addEventListener(event: string, listener: (...args: any[]) => any, param: boolean): void;
}

interface AdsManager {
  pause(): void;
  resume(): void;
  resize(w: number, h: number, mode: any): void;
  addEventListener(event: string, listener: (...args: any[]) => any): void;
  init(w: number, h: number, mode: any): void;
  start(): void;
  setVolume(vol: number): void;
  destroy(): void;
}

export const useIma = (container: ContainerRef, video: VideoRef, initiallyMuted = false) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [pause, setPause] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(initiallyMuted);

  useEffect(() => {
    imaLoaded
      .then(() => setLoaded(true))
      .catch(() => setFailed(true));
  }, []);

  useEffect(() => {
    if (!(videoUrl && loaded && container.current && video.current)) return;
    createPlayer(videoUrl, video.current, container.current, muted)
      .then(player => player.play());
  }, [ videoUrl, loaded, container.current, video.current ]);

  return {
    failed,
    loaded,
    setVideoUrl,
    setPause,
    setMuted,
  };
};

function createRequest(adTagUrl: string, muted: boolean, video: HTMLVideoElement) {
  const adsRequest = new window.google.ima.AdsRequest();
  adsRequest.adTagUrl = adTagUrl;
  adsRequest.setAdWillAutoPlay(false);
  adsRequest.setAdWillPlayMuted(muted);
  adsRequest.contentTitle = document.title;
  adsRequest.pageUrl = location.href;
  adsRequest.forceNonLinearFullSlot = true;
  adsRequest.linearAdSlotWidth = video.clientWidth;
  adsRequest.linearAdSlotHeight = video.clientHeight;
  adsRequest.nonLinearAdSlotWidth = video.clientWidth;
  adsRequest.nonLinearAdSlotHeight = video.clientHeight;
  return adsRequest;
}

function createManager(displayContainer: DisplayContainer, videoContainer: HTMLVideoElement, adsRequest: any): Promise<AdsManager> {
  const { ima } = window.google;

  const adsLoader: AdsLoader = new window.google.ima.AdsLoader(displayContainer);

  return new Promise((resolve, reject) => {
    const LOADED = ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED;
    const FAILED = ima.AdErrorEvent.Type.AD_ERROR;
    const resolveManager = (event: any) => resolve(event.getAdsManager(videoContainer));
    adsLoader.addEventListener(FAILED, reject, false);
    adsLoader.addEventListener(LOADED, resolveManager, false);
    adsLoader.requestAds(adsRequest);
  });
}

export const EVENT_START = 'start';
export const EVENT_PROGRESS = 'adProgress';
export const EVENT_COMPLETE = 'allAdsCompleted';

export async function createPlayer(url: string, videoRef: HTMLVideoElement, displayRef: HTMLDivElement, muted: boolean) {
  await imaLoaded;

  const { ima } = window.google;

  const container = new ima.AdDisplayContainer(displayRef, videoRef);
  const request = createRequest(url, muted, videoRef);
  const manager = await createManager(container, videoRef, request);
  const handlers: Record<string, Array<(...args: any[]) => void>> = {};

  const resizeHandler = () => {
    const { offsetWidth, offsetHeight } = displayRef;
    manager.resize(offsetWidth, offsetHeight, window.google.ima.ViewMode.NORMAL);
  };

  window.addEventListener('resize', resizeHandler);

  for (let event of Object.values(ima.AdEvent.Type as Record<string, string>)) {
    handlers[event] = [];
    manager.addEventListener(event, (...args) => {
      handlers[event].forEach(h => h(...args));
    });
  }

  return {
    on: (event: string, handler: (...args: any[]) => void) => handlers[event].push(handler),
    setVolume: (volume: number) => manager.setVolume(volume),
    setPause: (pause: boolean) => pause ? manager.pause() : manager.resume(),
    play: () => {
      videoRef.load();
      container.initialize();
      try {
        const { clientWidth, clientHeight } = videoRef;
        manager.init(clientWidth, clientHeight, ima.ViewMode.NORMAL);
        manager.start();
      } catch (err) {
        console.error(err);
        videoRef.play();
      }
    },
    destroy: () => {
      manager.destroy();
      container.destroy();
      displayRef.innerHTML = '';
      window.removeEventListener('resize', resizeHandler);
    }
  };
}
