import { useEffect, useRef, useState } from 'preact/hooks';

import { IStore, State, connect, actions } from '../store/';
import { createPlayer, EVENT_PROGRESS, EVENT_START, EVENT_COMPLETE } from './ima/';

type Player = Awaited<ReturnType<typeof createPlayer>>;

const VastIMAPlayer = ({ generalState, videoUrl, videoVolume, videoError, adStarted, adProgress, videoCompleted, manualPause }: IStore) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const displayRef = useRef<HTMLDivElement | null>(null);
  const [player, setPlayer] = useState<Player | null>(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (!player) return;
    const interval = setInterval(
      () => manualPause || !document.hasFocus() ? player.setPause(true) : player.setPause(false),
      200,
    );
    return () => clearInterval(interval);
  }, [manualPause, player]); 

  useEffect(() => {
    if (!player) return;
    player.setVolume(videoVolume);
  }, [player, videoVolume]);

  useEffect(() => {
    if (!videoRef.current || !displayRef.current || !videoUrl) return;
    createPlayer(videoUrl, videoRef.current, displayRef.current, !videoVolume)
      .then(player => setPlayer(player))
      .catch(err => videoError(err));
  }, [ videoRef.current, displayRef.current, videoUrl ]);

  useEffect(() => {
    if (!player) return;

    player.on(EVENT_START, adStarted);
    player.on(EVENT_PROGRESS, adProgress);
    player.on(EVENT_PROGRESS, () => setPlaying(true));
    player.on(EVENT_COMPLETE, videoCompleted);
    player.play();

    return () => {
      player.destroy();
    };
  }, [player]);

  const opacity = generalState === State.VIDEO ? 1 : 0;
  const showLoader = !playing && generalState === State.VIDEO;

  return (
    <>
      <div className="vast-media">
        <video ref={videoRef}
          style={{ width: '100%', height: '100%', opacity, transition: 'opacity 1s ease-in', backgroundColor: 'black' }}
          controls={false}
          playsInline={true}
          src="https://ams.cdn.arkadiumhosted.com/advertisement/video/loader_black.mp4"
          muted={!videoVolume}
          >
            Sorry, your browser doesn't support embedded videos.
        </video>
        <div className="display-ad-container" ref={displayRef} style={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0 }}></div>
        <div className="ark-preroll-loader" style={{visibility: showLoader ? 'visible' : 'hidden'}}></div>
        {/* <div class="ark-preroll-disclaimer">The game will start after the advertisement</div> */}
      </div>
    </>
  );
};

export default connect(['generalState', 'videoUrl', 'videoVolume', 'manualPause'], actions)(VastIMAPlayer);
