import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { connect, actions } from '../store';
import { ControlsPanel } from './controls/ControlsPanel';

import './VideoControls.css';

interface VideoControlsProps {
  generalTimeLeft: number;
  generalDuration: number;
  generalState: string;
  config: {
    showPauseButton: boolean;
    showTimer: boolean;
  };
  finish: () => void;
  setVolume: (volume: number) => void;
  handlePauseToggle: (isPaused: boolean) => void;
}

function VideoControls({
  generalTimeLeft,
  generalDuration,
  config,
  setVolume,
  handlePauseToggle
}: VideoControlsProps) {
  return (
    <ControlsPanel
      timeLeft={generalTimeLeft}
      duration={generalDuration}
      onVolumeToggle={setVolume}
      onPauseToggle={handlePauseToggle}
      showPauseButton={config.showPauseButton}
      showTimer={config.showTimer}
    />
  );
}

export default connect(
  ['generalTimeLeft', 'generalDuration', 'generalState', 'videoAdCount', 'config'],
  actions
)(VideoControls);
