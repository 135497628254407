import React, { useState } from 'react';

import muted from './muted.svg';
import unmuted from './unmuted.svg'

interface IProps {
  onToggle: (volume: number) => void;
}

export function MuteButton({ onToggle }: IProps) {
  const [vol, setVol] = useState(1);

  const onClick = () => {
    const newVol = vol ? 0 : 1;
    onToggle(newVol);
    setVol(newVol);
  };

  return (
    <div className={ `ark-mute-button`} onClick={onClick}>
      <img src={vol ? unmuted : muted} style={{ width: '100%', height: '100%' }}/>
    </div>
  );
};
