import { h } from 'preact';

interface TimerProps {
  timeLeft: number;
}

const formatTime = (seconds: number): string => {
  const duration = Number.isFinite(seconds) ? seconds : 30;
  return new Date(duration * 1000).toISOString().slice(14, 19);
};

export function Timer({ timeLeft }: TimerProps) {
  return (
    <div className="avp-panel-timer">
      {formatTime(timeLeft)}
    </div>
  );
}
