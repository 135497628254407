import { MuteButton } from './MuteButton';
import { PauseButton } from './PauseButton';
import { Timer } from './Timer';
import { ProgressBar } from './ProgressBar';

interface ControlsPanelProps {
  timeLeft: number;
  duration: number;
  onVolumeToggle: (volume: number) => void;
  onPauseToggle: (isPaused: boolean) => void;
  showPauseButton?: boolean;
  showTimer?: boolean;
}

export function ControlsPanel({
  timeLeft,
  duration,
  onVolumeToggle,
  onPauseToggle,
  showPauseButton = false,
  showTimer = false,
}: ControlsPanelProps) {
  return (
    <div className="avp-panel">
      <div className="avp-panel-top">
        <div className="avp-panel-mute">
          <MuteButton onToggle={onVolumeToggle} />
        </div>
        <div className="avp-panel-right">
          {showTimer && (
            <div className="avp-panel-timer">
              <Timer timeLeft={timeLeft} />
            </div>
          )}
          {showPauseButton && (
            <div className="avp-panel-pause">
              <PauseButton onToggle={onPauseToggle} />
            </div>
          )}
        </div>
      </div>
      <ProgressBar current={timeLeft} total={duration} />
    </div>
  );
}
