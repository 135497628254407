import { h } from 'preact';
import { useState } from 'preact/hooks';
import pauseIcon from './pause.svg';
import playIcon from './play.svg';

interface IPauseButtonProps {
  onToggle: (isPaused: boolean) => void;
}

export function PauseButton({ onToggle }: IPauseButtonProps) {
  const [isPaused, setIsPaused] = useState(false);

  const handleClick = () => {
    const newPauseState = !isPaused;
    setIsPaused(newPauseState);
    onToggle(newPauseState);
  };

  return (
    <div className="ark-pause-button" onClick={handleClick}>
      <img
        src={isPaused ? playIcon : pauseIcon}
        alt={isPaused ? 'Play' : 'Pause'}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
}