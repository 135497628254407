import { h } from 'preact';

interface ProgressBarProps {
  current: number;
  total: number;
}

export function ProgressBar({ current, total }: ProgressBarProps) {
  const width = total ? (100/total * (total-current)) : 0;
  
  return (
    <div className="avp-panel-progress">
      <div 
        className="avp-panel-progress-line" 
        style={{ width: `${width}%` }}
      />
    </div>
  );
}